export class Colors {
  primary: string = '#00bcf2';
  primary_dark: string = '#00bcf2';
  secondary: string = '#00dca0';
  danger: string = '#eb445a';
  warning: string = '#ffc409';
  light: string = '#f4f5f8';
  gray: string = '#989aa2';
  dark: string = '#001f38';
  black: string = '#000000';
  white: string = '#ffffff';
  primary_contrast: string = '#ffffff';
  toolbar: string = '#001f38';
}
export class AppConfig {
  colors: Colors = new Colors();
  title: string = 'Glup';
  appID: string = '';
  appHost: string = 'gluplading.notodden-energi.no';
  showTitle: boolean = false;
  defaultLang: string = 'nb';
  appToken: string = '8bd9c35b-6ff9-452f-aee4-e00290c8e4fd';
  pushAppID: string = 'b2a7e4f3-22fe-4945-b360-c5dd9fafc186';
  pushSenderID: string = '986355636543';
  root: string = 'https://scpay.smartcharge.io';
  readonly isWhitelabel: boolean = this.appToken ? true : false;
  stationIcon: StationIcon = { height: 44, width: 30 };
  availableLangs: string[] = ['nb', 'en', 'da', 'sv'];
  toolbarLogoHeight: string = '1.9em';
  privacyPolicy: any = {
    nb: 'https://notodden-energi.no/formelle-skriv/#avtalevilkar',
    en: 'https://notodden-energi.no/formelle-skriv/#avtalevilkar'
  };
  termsAndConditions: any = {
    nb: 'https://notodden-energi.no/formelle-skriv/#avtalevilkar',
    en: 'https://notodden-energi.no/formelle-skriv/#avtalevilkar'
  };
  contact: any = {
    external: false,
    email: 'kundesenter@notodden-energi.no',
    phone: '+47 35025050',
    web: '',
    chat: '',
    customerService: {
      nb: 'https://notodden-energi.no/kontakt/',
      en: 'https://notodden-energi.no/kontakt/'
    }
  };
  constructor() {}
}

interface StationIcon {
  height: number;
  width: number;
}
